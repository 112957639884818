import React from "react";
import MarineAvocate from "../assets/marinePro3.jpg";
import iconeCV from "../assets/iconeCV.png";
import CVFile from "../assets/CV-MarineBruniere-Avocat.pdf";

import "./NavBar.css";

function Presentation() {
  // Fonction pour déclencher le téléchargement du CV
  const telechargerCV = () => {
    const link = document.createElement("a");
    link.href = CVFile;
    link.target = "_blank"; // Ouvre le lien dans un nouvel onglet
    link.download = "CV-MarineBruniere-Avocat.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      id="cabinet"
      className="flex flex-col items-center justify-center bg-cover"
    >
      <div className="w-full text-center p-10  bg-gray-900 ">
        <h1
          className="text-3xl md:text-4xl font-trebuc md:mb-4 custom-color
        
        uppercase 
        relative cursor-pointer transition-all duration-500
       before:content-[''] before:absolute before:bottom-[-15px] before:left-1/2 before:-translate-x-1/2 
       before:w-0 before:h-0.5 before:rounded-full before:opacity-0 before:transition-all before:duration-500
        before:bg-gradient-to-r before:from-gray-200 before:via-gray-800 before:to-gray-200 hover:before:w-full hover:before:opacity-100
        
        "
        >
          Le cabinet
        </h1>
      </div>

      <div className="flex flex-col md:flex-row  bg-gray-300">
        <div className="w-full md:w-1/3 h-4/5 md:h-full bg-gray-300 my-auto">
          <img
            className="w-3/4 xl:w-3/4 h-full object-cover rounded-full mx-auto xl:mx-32 py-12 xl:ml-24"
            src={MarineAvocate}
            alt="Marine Avocate"
          />
        </div>

        <div className=" bg-gray-300  flex flex-col justify-start md:ml-0 w-full md:w-2/3 md:h-2/3 2xl:mt-28 xl:mt-12 lg:mt-12 p-8 text-left ">
          <div className="    rounded-xl  p-6 xl:p-12 text-justify xl:mx-24   my-auto bg-gray-900 opacity-100">
            <p className=" md:text-xl font-trebuc  md:px-2 text-gray-300">
              Maître Marine BRUNIERE est avocate au barreau de Lyon et
              intervient sur toutes les problématiques relatives au droit de la
              fonction publique. Dans ce cadre, elle assiste tant les personnes
              et les établissements publics que les agents publics. Elle est
              également formée aux techniques de la médiation et accompagne ses
              clients dans ce processus.
            </p>
            <p className="mt-4  md:text-xl font-trebuc text-gray-300">
              Après avoir travaillé plusieurs années aux côtés du Professeur
              Didier JEAN-PIERRE, spécialiste de la fonction publique, elle a
              décidé de fonder son cabinet consacré aux personnels du secteur
              public.
            </p>
            <p className="mt-4  md:text-xl font-trebuc text-gray-300">
              Par ailleurs, Maître BRUNIERE enseigne le droit administratif dans
              le cadre de travaux dirigés à l’Université Lumière – Lyon 3.
            </p>
            
            {/*       <p className="mt-4 text-sm md:text-2xl  font-trebuc">
              Elle a réalisé un stage de 6 mois au sein de la chambre fonction
              publique d’Etat du Tribunal administratif de LYON lors de sa
              formation à l’Ecole des Avocats de la Région Rhône Alpes ainsi
              qu’un stage de 6 mois dans un cabinet de droit public lyonnais.
            </p>*/}
            {/*  <p className="mt-4 text-sm md:text-2xl  font-trebuc">
              Elle est chargé d’enseignement en droit public à l’Université
              d’Aix Marseille.
  </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
